<template>
  <div class="form">
    <div class="content" v-if="!isMobile || !$store.state.popup.id && (!isMobile || !$store.state.is_popup_shown)">
      <Backlink class="desktop" />
      <div class="content__container content__container_profile">
        <p class="promo desktop">{{ 'payments' | localize }}</p>

        <div v-if="cards.length == 0">
          <p class="info">{{ 'no_cards' | localize }}</p>
        </div>

        <div class="cards">
          <Card v-for="card in cards" :key="card.id" :cardData="card" :select="select" />
        </div>

        <div class="error__container">
          <p class="error" v-if="error">{{ error | localize }}</p>
        </div>
      </div>
    </div>

    <div class="popup popup_active popup_info" v-if="$store.state.popup.id">
      <div class="popup__overlay popup__overlay_active">
        <div class="popup__center" @click.self="$store.commit('togglePopupText')">
          <div class="popup__window popup__window_blue">
            <button class="close popup__close" @click.self="$store.commit('togglePopupText')"></button>
            <div class="popup__logo"><router-link to="/" class="logo">BANKIM<span>ONLINE</span></router-link></div>
            <div class="popup__scrollcontainer ">
              <div class="popup__scrollcontainer_inner">
                <AddCard v-if="$store.state.popup.id == 'card'" />
                <PaymentService v-else from_payments :service="service" :card_id="$store.state.popup.id > 1 ? $store.state.popup.id : null" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink'
import AddCard from '@/components/profile/AddCard'
import Card from '@/components/profile/Card'
import PaymentService from '@/components/PaymentService.vue'
import { commonMixin } from '@/mixins/commonMixin.js'

export default {
  name: 'Payments',
  mixins: [commonMixin],
  props: { 
    select: { type: Boolean, default: false },
    service: { type: Number, default: null }
  },
  components: { Backlink, AddCard, PaymentService, Card },
  data: () => ({
    cards: [],
    error: null
  }),
  mounted () {
    this.$store.commit('loading', true)
    this.axios
      .get('user_cards', { params: { user_id: this.$store.state.user.id || 0 } })
      .then((response) => {
        this.cards = response.data || []
        this.cards.push({class: 'card__default', number: '0000 0000 0000 0000', dt: 'MM/YY', button: 'addNewCard'})
        this.$store.commit('loading', false)
      })
      .catch((error) => {
        this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
        this.$store.commit('loading', false)
      })
  }
}
</script>

<style lang="scss" scoped>
.cards {
  display: flex;
  flex-flow: wrap;
  margin: 0 -25px;
}

// Media style
@media screen and (max-width: 1024px) {
  .cards {
    margin: 0;
    display: block;
  }
}
</style>
